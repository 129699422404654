import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.submenu()
        NavbarComponent.langsSwitcher()
        NavbarComponent.select()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const tools = $(NavbarEnum.NAVBAR_TOOLS)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                $(NavbarEnum.BODY).removeClass('hp-header-animated')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }

            $(NavbarEnum.BODY).removeClass('header-is-hidden')

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })
    }

    static submenu() {
        const menuItemLink = $('[data-trigger="burger-submenu-toggle"]')
        const subMenuItem = $('[data-element="burger-submenu"]')

        // Open submenu on mobile
        if (window.matchMedia('(max-width:1241px)').matches) {
            menuItemLink.removeClass('submenu-open')
            subMenuItem.removeClass('active')

            // Open/close submenu
            menuItemLink.on('click', function (e) {
                const targetItem = $(this).data('element-menu-item')
                const submenuKey = $(`[data-submenu-key="${targetItem}"`)

                // first click (open submenu)
                menuItemLink.removeClass('submenu-open')
                $(this).toggleClass('submenu-open')

                subMenuItem.removeClass('submenu-open')
                submenuKey.toggleClass('submenu-open')
                $(NavbarEnum.BODY).addClass('submenu-open')

                return false
            })
        }
        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            subMenuItem.removeClass('submenu-open')
            menuItemLink.removeClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_CLOSE).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('menu-open')
            subMenuItem.removeClass('submenu-open')
            menuItemLink.removeClass('submenu-open')
            if ($('.navbar--onscroll').length) {
                NavbarComponent.changeLogoColor('classic')
            }
        })
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //Open List
        $(current_lang).on('click', () => {
            $(NavbarEnum.LIST_LANG).toggleClass('tools__item__languages__langs--open')

            //Hide list lang on outside click
            if ($(NavbarEnum.LIST_LANG).hasClass('tools__item__languages__langs--open')) {
                $(window).click((e) => {
                    if (
                        e.target.getAttribute('data-id') !== 'current-lang' &&
                        e.target.className !== 'current__lang'
                    ) {
                        $(NavbarEnum.LIST_LANG).removeClass('tools__item__languages__langs--open')
                    }
                })
            }
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
            setTimeout(() => {
                const currentLangCode = $('html').attr('lang')
                $('.tools__item__languages__current span').html(currentLangCode.substr(0, 2))
            }, '1000')
        })

        // Fix current lang
        setTimeout(() => {
            const currentLangCode = $('html').attr('lang')
            $('.tools__item__languages__current span').html(currentLangCode.substr(0, 2))
        }, '1000')
    }

    static select() {
        $(NavbarEnum.BTN_SELECT).on('click', function () {
            $(this).toggleClass('select-open')
        })

        $(document).mouseup((e) => {
            if (
                !$(NavbarEnum.BTN_SELECT).is(e.target) &&
                $(NavbarEnum.BTN_SELECT).has(e.target).length === 0
            ) {
                $(NavbarEnum.BTN_SELECT).removeClass('select-open')
            }
        })
    }
}
