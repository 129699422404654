export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_MENU_CLOSE = '[data-trigger="burger-menu-close"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static LIST_LANG = '[data-id="list-lang"]'
    static BTN_SELECT = '[data-trigger="navbar-tools-select"]'
}
